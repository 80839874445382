// extracted by mini-css-extract-plugin
export var active = "resource-card-module--active--3b856";
export var background = "resource-card-module--background--f16e5";
export var content = "resource-card-module--content--a63e3";
export var dark = "resource-card-module--dark--eaf40";
export var description = "resource-card-module--description--259ce";
export var divider = "resource-card-module--divider--da47f";
export var fill = "resource-card-module--fill--07b90";
export var imageContainer = "resource-card-module--image-container--0bbb5";
export var info = "resource-card-module--info--da537";
export var label = "resource-card-module--label--97868";
export var link = "resource-card-module--link--c43ab";
export var root = "resource-card-module--root--c6669";
export var title = "resource-card-module--title--6c9fc";
export var titleWithDescription = "resource-card-module--title--with-description--7d4dc";